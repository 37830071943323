import { LoadingOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button } from "antd";
import { SegmentedValue } from "antd/es/segmented";
import { EventSourcePolyfill } from "event-source-polyfill";
import { createContext, lazy, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { repositoryApi } from "@api";
import { ArrowRightSvg } from "@assets/svg";
import { Flex } from "@components";
import { explorationCategories, storage } from "@defines";
import { RepoModel } from "@types";
import { handleDownloadFile } from "@utils";

import { useProgress } from "../providers/ProgressProvider";

export const ExportBtn = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary100}80;

  div {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 6px;
    }
  }
`;

const GraphPage = lazy(() => import("@pages/exploration/graph"));
const FilePage = lazy(() => import("@pages/exploration/file"));
const AssessmentPage = lazy(() => import("@pages/exploration/assessment"));
const TopbarLayout = lazy(() => import("@layouts/TopbarLayout"));

const ProjectContext = createContext<{ project: RepoModel }>({} as { project: RepoModel });

export const useProject = () => {
  return useContext(ProjectContext);
};

export const ExplorationRouter = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { projectId, explorationCategory } = useParams();
  const [downloadExport, setDownloadingExport] = useState(false);
  const [downloadDeadcode, setDownloadingDeadcode] = useState(false);
  const [downloadAssessment, setDownloadingAssessment] = useState(false);
  const [project, setProject] = useState<RepoModel>();

  const { progressPercent } = useProgress();

  const onChangeSegmented = (value: SegmentedValue) => {
    navigate(`/exploration/${projectId}/${value}`);
  };

  const onClickBack = () => {
    navigate("/workspace/repository");
  };

  const handleExportFile = (downloadData: Blob | null, filename: string) => {
    if (!downloadData) return;
    try {
      handleDownloadFile(downloadData, filename);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  };

  const exportAssessment = async () => {
    if (!projectId) return;
    setDownloadingAssessment(true);
    const exportResponse = await repositoryApi.getAssessmentRepository(projectId);

    if (!exportResponse) return;
    handleExportFile(exportResponse, `${project?.name}_assessment_report`);
    setDownloadingAssessment(false);
  };

  const exportAllReport = () => {
    if (!projectId) return;
    const eventSource = new EventSourcePolyfill(
      `${process.env.REACT_APP_API_URL}/api/repository/${projectId}/status_export_file`,
      { headers: { Authorization: `Bearer ${localStorage.getItem(storage.TOKEN)}` } }
    );

    setDownloadingExport(true);
    const closeEventSource = () => {
      eventSource.close();
    };

    eventSource.onmessage = async event => {
      const response = JSON.parse(event.data);

      if (response.status !== "done") return;
      try {
        const exportResponse = await repositoryApi.getFileExcelRepository(projectId);

        if (!exportResponse) return;
        handleExportFile(exportResponse, `${project?.name}_export_report`);
        setDownloadingExport(false);
      } finally {
        closeEventSource();
      }
    };

    eventSource.onerror = closeEventSource;
  };

  const exportDeadcode = async () => {
    if (!projectId) return;
    setDownloadingDeadcode(true);
    const exportResponse = await repositoryApi.getReportDeadcodeRepository(projectId);

    if (!exportResponse) return;
    handleExportFile(exportResponse, `${project?.name}_deadcode_report`);
    setDownloadingDeadcode(false);
  };

  useEffect(() => {
    const fetchProject = async () => {
      if (!projectId) return;
      const response = await repositoryApi.getRepositoryRequest(projectId);

      if (!response?.data) return;
      setProject(response.data);
    };

    fetchProject();
  }, [projectId]);

  return (
    <TopbarLayout
      segmented={{
        options: [
          { label: t("component.repo_tab.assessment"), value: "assessment" },
          { label: t("component.repo_tab.exploration"), value: "file" },
          { label: t("component.repo_tab.graph"), value: "graph" }
        ],
        onChange: onChangeSegmented,
        defaultValue: "",
        value: explorationCategory
      }}
      onClickBack={onClickBack}
      progress={progressPercent === 100 ? undefined : { percent: progressPercent }}
    >
      {project ? (
        <ProjectContext.Provider value={{ project }}>
          <Flex gap={12} style={{ position: "absolute", right: 40, top: 28 }}>
            <ExportBtn type='primary' onClick={exportAssessment} disabled={downloadAssessment}>
              {downloadAssessment ? (
                <div>
                  {t("button.running")} ... <LoadingOutlined />
                </div>
              ) : (
                <div>
                  <span>{t("button.export_assessment")}</span> <ArrowRightSvg className='icon' />
                </div>
              )}
            </ExportBtn>
            <ExportBtn type='primary' onClick={exportDeadcode} disabled={downloadDeadcode}>
              {downloadDeadcode ? (
                <div>
                  {t("button.running")} ... <LoadingOutlined />
                </div>
              ) : (
                <div>
                  <span>{t("button.export_deadcode")}</span> <ArrowRightSvg className='icon' />
                </div>
              )}
            </ExportBtn>
            <ExportBtn type='primary' onClick={exportAllReport} disabled={downloadExport}>
              {downloadExport ? (
                <div>
                  {t("button.running")} ... <LoadingOutlined />
                </div>
              ) : (
                <div>
                  <span>{t("button.export_all")}</span> <ArrowRightSvg className='icon' />
                </div>
              )}
            </ExportBtn>
          </Flex>
          {explorationCategory === explorationCategories.EXPLORATION_ASSESSMENT ? (
            <AssessmentPage />
          ) : explorationCategory === explorationCategories.EXPLORATION_FILE ? (
            <FilePage />
          ) : (
            <GraphPage />
          )}
        </ProjectContext.Provider>
      ) : null}
    </TopbarLayout>
  );
};

import { css } from "@emotion/react";

import { colors } from "./colors";

export const customStyle = css`
  /* stylelint-disable */
  .ant-input {
    height: 42px;
  }

  .ant-select-lg .ant-select-selector {
    border-radius: 24px !important;
    padding: 0 16px !important;
  }

  .ant-input:focus,
  .ant-input-number-focused,
  .ant-picker-focused:not(.ant-picker-status-error),
  .ant-select-focused:not(.ant-select-status-error) .ant-select-selector,
  .ant-input-number-affix-wrapper-focused:not(.ant-input-number-affix-wrapper-status-error),
  .ant-input-affix-wrapper-focused:not(.ant-input-affix-wrapper-status-error) {
    box-shadow: 0 0 0 2px ${colors.primary100}B4 !important;
  }

  .ant-input-status-error,
  .ant-picker-status-error,
  .ant-input-affix-wrapper-status-error,
  .ant-select-status-error .ant-select-selector {
    box-shadow: 0 0 0 2px ${colors.danger}B4 !important;
  }

  .ant-btn-primary {
    box-shadow: none;
  }

  .ant-dropdown-menu,
  .ant-select-dropdown,
  .ant-table-filter-dropdown,
  .ant-picker-panel-container {
    background-color: ${colors.grey} !important;
    border: solid 1px ${colors.primary100};
  }

  .ant-table-filter-dropdown {
    .ant-table-filter-dropdown-btns {
      border: none !important;
    }
    .ant-dropdown-menu-item-selected {
      background-color: ${colors.primary100}50 !important;
    }
  }

  .ant-cascader-menus {
    .ant-cascader-menu {
      border-inline-end: none !important;
    }
  }

  .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
  .ant-cascader-menu-item-active,
  .ant-select-item-option-selected {
    background-color: ${colors.primary100}50 !important;
  }

  /* > Hover item menu */
  .ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${colors.primary100}33 !important;
  }

  .ant-picker-panel {
    .ant-picker-header {
      border-bottom: none !important;
    }
    .ant-picker-footer {
      border-top: none !important;
    }
    .ant-picker-time-panel-column {
      border-inline-start: none !important;
    }
  }

  .ant-slider {
    .ant-slider-track {
      background-color: ${colors.primary100} !important;
    }
  }

  .ant-modal-content {
    background-color: ${colors.grey} !important;
    border: solid 1px ${colors.primary100} !important;

    .ant-modal-title {
      background-color: ${colors.grey} !important;
    }
  }

  .ant-card.ant-card-bordered {
    border: solid 1px ${colors.primary100} !important;
  }

  .ant-layout {
    background: ${colors.colorBgContainer};
  }

  .ant-layout-sider {
    background: ${colors.neutral} !important;
    padding: 40px 16px;
  }

  .ant-tabs {
    .ant-tabs-tab {
      padding: 0 0 18px;
    }
    .ant-tabs-nav::before {
      display: none;
    }
  }

  .ant-menu {
    border-inline: none !important;
    background: ${colors.neutral} !important;

    .ant-menu-item,
    .ant-menu-submenu-title {
      margin: 0;
      width: 100%;
      height: 56px !important;
      border-radius: 0px 16px 16px 0px !important;
      gap: 16px;

      .ant-menu-title-content {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .ant-menu-item {
      &.ant-menu-item-selected {
        color: ${colors.grey900};
        background-color: ${colors.primary100};
      }
    }
  }

  .ant-breadcrumb-link > a {
    color: ${colors.primary100};
  }

  .ant-segmented {
    color: ${colors.white};
    background: ${colors.primary100}33;

    .ant-segmented-group {
      gap: 24px;

      .ant-segmented-item {
        padding: 4px 12px;
      }
      .ant-segmented-item-label {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        min-height: 0px;
        padding: 0;
      }
    }

    .ant-segmented-thumb-motion-appear {
      background: ${colors.primary100};
    }

    &,
    .ant-segmented-thumb-motion-appear,
    .ant-segmented-item {
      border-radius: 999px;
    }

    .ant-segmented-item-selected {
      background: ${colors.primary100};
      .ant-segmented-item-label {
        color: ${colors.grey900};
      }
    }
  }

  .ant-progress {
    .ant-progress-outer {
      background-color: ${colors.primary100}19;
    }
    &.ant-progress-default .ant-progress-bg {
      background-color: ${colors.primary100} !important;
    }
  }

  .ant-tooltip {
    .ant-tooltip-inner,
    .ant-tooltip-arrow::after,
    .ant-tooltip-arrow::before {
      background-color: #000;
    }
  }

  .ant-table {
    .ant-table-thead {
      .ant-table-cell {
        background: ${colors.primary100} !important;
        border-radius: 0 !important;
        border-top: solid 1px ${colors.grey500} !important;
        color: ${colors.grey900} !important;

        svg {
          fill: ${colors.grey900} !important;
        }

        &::before {
          display: none;
        }
      }
    }
    .ant-table-tbody .ant-table-cell {
      background: ${colors.grey800} !important;
    }
    .ant-table-cell {
      border-right: solid 1px ${colors.grey500} !important;
      border-bottom: solid 1px ${colors.grey500} !important;
      font-size: 16px;
      line-height: 24px;
      padding: 12px 16px !important;
    }
    .ant-table-cell.ant-table-cell-row-hover {
      background: ${colors.secondary100} !important;
    }
    .ant-table-row .ant-table-cell:first-of-type {
      border-left: solid 1px ${colors.grey500} !important;
    }
  }

  .ant-notification {
    .cobol-notification {
      /* border-radius: 16px; */
      border-radius: 999px;
      background-color: ${colors.primary100};
      padding: 10px 12px;
      width: unset;

      &,
      .ant-notification-notice-description,
      .ant-notification-notice-message {
        color: ${colors.grey900};
      }

      .ant-notification-notice-description,
      .ant-notification-notice-message {
        margin: 0 !important;
        padding: 0 !important;
        max-width: 500px;
      }

      .ant-notification-notice-close {
        display: none;
      }
    }
  }

  .chartTooltip {
    .ant-tooltip-inner {
      background-color: black;
      border: 1px solid ${colors.primary100};
    }
  }
  /* stylelint-enable */

  .ant-btn {
    border-radius: 16px;
    border: none;
    line-height: 20px;
    font-weight: 600;
  }
`;

// ant-dropdown-menu

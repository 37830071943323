import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { repositoryApi } from "@api";
import { storage } from "@defines";
import { useController } from "@hooks";
import {
  appActions,
  migrationRepositoryActions,
  migrationRepositorySelector,
  repositoryActions,
  userSelector
} from "@store";

export const useHandleMigrationRepository = () => {
  const refQueue = useRef<NodeJS.Timeout>();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const migrationRepositoryData = useSelector(
    migrationRepositorySelector.selectMigrationRepositoryData
  );
  const migrationRepositoryId = useSelector(
    migrationRepositorySelector.selectMigrationRepositoryId
  );
  const migrationTreeView = useSelector(
    migrationRepositorySelector.selectMigrationRepositoryTreeView
  );
  const migrationCopyGraph = useSelector(
    migrationRepositorySelector.selectMigrationRepositoryCopyGraph
  );
  const migrationCluster = useSelector(
    migrationRepositorySelector.selectMigrationRepositoryCluster
  );
  const mgrationDone = useSelector(migrationRepositorySelector.selectMigrationRepositoryDone);
  const userData = useSelector(userSelector.selectData);
  const { navigateUnauthen } = useController();

  const clearRefQueue = () => {
    if (refQueue.current) {
      clearTimeout(refQueue.current);
      refQueue.current = undefined;
    }
  };

  const initialize = () => {
    const repo = localStorage.getItem(storage.REPO);
    dispatch(repositoryActions.setRepositoryData(repo ? JSON.parse(repo) : undefined));
    const migrationRepo = localStorage.getItem(storage.MIGRATION_REPO);
    dispatch(
      migrationRepositoryActions.setMigrationRepositoryData(
        migrationRepo ? JSON.parse(migrationRepo) : undefined
      )
    );
  };

  const tryFetch = async (handle: () => Promise<void>, options?: { time?: number }) => {
    let success = false;
    try {
      await handle();
      success = true;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        success = true;
        navigateUnauthen(error);
      }
      // eslint-disable-next-line
      console.log(error);
    } finally {
      if (!success) {
        clearRefQueue();
        refQueue.current = setTimeout(() => {
          clearRefQueue();
          tryFetch(handle, options);
        }, options?.time || 1000);
      }
    }
  };

  const fetchFileDetailRequest = (id: string) => {
    tryFetch(async () => {
      const res = await repositoryApi.getTreeViewDataRequest(id);

      if (!res?.data) return;
      dispatch(migrationRepositoryActions.setTreeView(res.data));
      dispatch(
        appActions.setNotificationData({
          message: t("notification.git_in_progress"),
          type: "pending"
        })
      );
    });
  };

  useEffect(() => {
    initialize();
  }, [userData]);

  useEffect(() => {
    if (!migrationTreeView && migrationRepositoryId) fetchFileDetailRequest(migrationRepositoryId);
  }, [migrationTreeView, migrationRepositoryId]);

  useEffect(() => {
    if (migrationTreeView && !mgrationDone) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { copy_graph, tree_view, clusters, ...storageData } = migrationRepositoryData;
      localStorage.setItem(
        storage.MIGRATION_REPO,
        JSON.stringify({ ...storageData, mgrationDone: true })
      );
      dispatch(
        appActions.setNotificationData({
          message: t("notification.all_done"),
          type: "success",
          timeout: 5000
        })
      );
    }
  }, [
    migrationTreeView,
    migrationCopyGraph,
    migrationCluster,
    migrationRepositoryData,
    mgrationDone
  ]);
};

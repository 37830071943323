import { Api, api } from "./config";

class UserApi {
  instance: Api;

  constructor(params: Api) {
    this.instance = params;
  }

  public doLoginRequest = (params: FormData) => {
    return this.instance.post("/api/auth/token", params);
  };
}

export const userApi = new UserApi(api);

import { LoadingOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button } from "antd";
import { SegmentedValue } from "antd/es/segmented";
import { lazy, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { repositoryApi } from "@api";
import { ArrowRightSvg } from "@assets/svg";
import { ROUTERS, migrationRouters } from "@defines";
import { migrationRepositorySelector } from "@store";
import { handleDownloadFile } from "@utils";

const FilePage = lazy(() => import("@pages/migration/file"));

const TopbarLayout = lazy(() => import("@layouts/TopbarLayout"));

export const ExportBtn = styled(Button)`
  background-color: rgba(246, 208, 83, 0.5);
  position: absolute;
  right: 32px;
  top: 17px;

  div {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 6px;
    }
  }
`;

export const MigrationRouter: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { migrationCategory } = useParams();
  const [segmentedValue, setSegmentedValue] = useState<string>();
  const refQueue = useRef<NodeJS.Timeout>();
  const [exportProgress, setExportProgress] = useState<boolean>(false);
  const data = useSelector(migrationRepositorySelector.selectMigrationRepositoryData);
  const repoId = useSelector(migrationRepositorySelector.selectMigrationRepositoryId);

  const onChangeSegmented = (value: SegmentedValue) => {
    navigate(value as string);
  };

  const onClickBack = () => {
    navigate(ROUTERS.WORKSPACE_MIGRATION_REPOSITORY);
  };

  useEffect(() => {
    if (!migrationCategory) {
      return;
    }

    const router = Object.values(migrationRouters).find(o => o.includes(migrationCategory));

    if (router) setSegmentedValue(router);
  }, [migrationCategory]);

  const clearRefQueue = () => {
    if (refQueue.current) {
      clearTimeout(refQueue.current);
      refQueue.current = undefined;
    }
  };

  const checkExportFile = async () => {
    if (exportProgress) return;
  };

  const handleExportFile = async () => {
    if (exportProgress) return;
    if (!repoId) return;
    try {
      setExportProgress(true);
      const res = await repositoryApi.getFileExcelRepository(repoId);

      if (!res) return;
      handleDownloadFile(res, data?.name);
      setExportProgress(false);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  };

  useEffect(() => {
    // check process
    if (repoId) {
      checkExportFile();
    }
    return () => clearRefQueue();
  }, [repoId]);

  return (
    <TopbarLayout
      segmented={{
        options: [
          { label: t("component.menu.migration_assessment"), value: ROUTERS.MIGRATION_FILE }
        ],
        onChange: onChangeSegmented,
        defaultValue: "",
        value: segmentedValue
      }}
      onClickBack={onClickBack}
      progress={undefined}
    >
      <ExportBtn type='primary' onClick={handleExportFile}>
        {exportProgress ? (
          <div>
            {t("button.running")} ... <LoadingOutlined />
          </div>
        ) : (
          <div>
            <span>{t("button.export_all")}</span> <ArrowRightSvg className='icon' />
          </div>
        )}
      </ExportBtn>
      <FilePage />
    </TopbarLayout>
  );
};
